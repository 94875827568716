import React from 'react'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { v4 as uuid } from 'uuid'

// import PopUp from '../../../shared/popup/PopUp'
import CheckPopUp from './CheckPopUp'
import TransactionsCheck from '../../popup-content/TransactionsCheck'

import styles from './TransactionTable.module.scss'

export default function TransactionTable() {
  const [isPopUpVisible, setIsPopUpVisible] = useState(false)
  const [currentTransaction, setCurrentTransaction] = useState(null)
  const { purchasesPerDate, status, error } = useSelector((state) => state.purchaseSlice)

  const CheckModal = ({ data }) => {
    const { ofd, date_time, shop_address, sum } = data
    return (
      <CheckPopUp
        onClose={() => {
          setIsPopUpVisible(false)
          setCurrentTransaction(null)
        }}
      >
        <TransactionsCheck
          onClose={() => {
            setIsPopUpVisible(false)
            setCurrentTransaction(null)
          }}
          ofd={ofd}
          time={date_time}
          adress={shop_address}
          sum={sum}
        />
      </CheckPopUp>
    )
  }

  const tableRows = (dataArr) => {
    return dataArr.map((transaction) => {
      const { date, shop_name, sum, bonus_accured } = transaction
      //   const rowDate = date.split('/').reverse()
      const dateDay = new Date(date)
      const day = dateDay.getDate()
      const month = dateDay.getMonth() + 1

      return (
        <div className={`${styles['body__row']}`} key={uuid()}>
          <div>
            <span className={styles['month-desktop']}>{dateDay.toLocaleString().slice(0, 10)}</span>
            <span className={styles['month-mobile']}>{`${day}.${
              month < 10 ? '0' + month : month
            }`}</span>
          </div>
          <div>{shop_name}</div>
          <div className={styles.money}>
            <span className={styles['money__value']}> {`${sum}`} </span>
            <span className={styles['money-mobile']}>
              <svg
                width="7"
                height="9"
                viewBox="0 0 7 9"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <line y1="2.39453" x2="7" y2="2.39453" stroke="#A2A2A2" strokeWidth="1.5" />
                <line y1="0.5" x2="7" y2="0.5" stroke="#A2A2A2" />
                <line
                  x1="3.55078"
                  y1="3.14453"
                  x2="3.55078"
                  y2="9.00037"
                  stroke="#A2A2A2"
                  strokeWidth="1.5"
                />
              </svg>
            </span>
          </div>
          <div className={`${styles['body__row-bonus']}`}>
            <span className={styles['bonus-desktop']}>{`${Math.ceil(bonus_accured)} бонусов`}</span>
            <span className={styles['bonus-mobile']}>{`${Math.ceil(bonus_accured)} б`}</span>
            <button
              className={`${styles['button-show']} button`}
              onClick={() => {
                setIsPopUpVisible(true)
                setCurrentTransaction(transaction)
              }}
            ></button>
          </div>
        </div>
      )
    })
  }

  const content = purchasesPerDate ? tableRows(purchasesPerDate?.transactions) : null

  return (
    <div className={`${styles.table}`}>
      <div className={`${styles.header}`}>
        <div className={`${styles['header__item']}`}>Дата</div>
        <div className={`${styles['header__item']}`}>Магазин</div>
        <div className={`${styles['header__item']}`}>Сумма покупки</div>
        <div className={`${styles['header__item']}`}>Начислено бонусов</div>
      </div>
      <div className={`${styles['body']}`}>
        {content}
        {isPopUpVisible && <CheckModal data={currentTransaction} />}
      </div>
    </div>
  )
}
