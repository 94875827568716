import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { login } from '@/store/slices/authSlice'
import { toggleIsOpenAuthForm } from '@/store/slices/uiSlice'
import { useNavigate } from 'react-router-dom'

//hooks
import useFormField from '@/utils/hooks/useFormField'

//components
import TextInputField from '@components/shared/form/TextInputField'
import PasswordInputField from '@components/shared/form/PasswordInputField'
import AuthActions from './AuthActions'

const LoginForm = ({ changeFormType }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  // Selectors
  const { loginError, loading, isLoggedIn } = useSelector((state) => state.authSlice)

  // fields
  const email = useFormField('')
  const password = useFormField('')
  const formFields = {
    email,
    password,
  }

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(toggleIsOpenAuthForm(false))
      navigate('/cabinet/profile')
    }
    //    if (input.matches(':-internal-autofill-selected')) console.log();
  }, [isLoggedIn])

  const handleSubmitForm = async (e) => {
    e.preventDefault()

    //validate form fields before sumbit
    if (Object.values(formFields).some((field) => field.input.error)) {
      Object.values(formFields).forEach((field) => field.setters.showError())

      return
    }

    dispatch(
      login({
        email: email.input.value,
        password: password.input.value,
      })
    )
  }

  return (
    <form className={'auth-form'} onSubmit={handleSubmitForm}>
      <h4 className={'auth-form__title'}>Авторизация</h4>
      <div className={'auth-form__body'}>
        {/* Login */}
        <TextInputField meta={email} type="text" placeholder="Email" required />
        {/* Password */}
        <PasswordInputField meta={password} placeholder="Пароль" required />

        {/* Forget Password */}
        <div className={'auth-login-form__forget-password form__group'}>
          <a className="button-readmore" onClick={() => changeFormType('forget-password')}>
            Забыли пароль?
          </a>
        </div>

        {loginError && <span className="form__auth-error">{loginError}</span>}
      </div>
      <div className={'auth-form__footer'}>
        <AuthActions
          changeButtonLabel="Регистрация"
          changeFormType={() => changeFormType('signup')}
          actionButtonLabel="Войти"
          actionButtonDisabled={!email.input.value || !password.input.value || loading}
          onAction={handleSubmitForm}
        />
      </div>
    </form>
  )
}

export default LoginForm
