import useHttp from '@api'

const enpoints = {
  clubs: '/cabinet/clubs',
  usersClubs: '/cabinet/clubs/user-clubs',
  joinClub: (clubId) => `/cabinet/clubs/add-client?club_id=${clubId}`,
  leaveClub: (clubId) => `/cabinet/clubs/remove-client?club_id=${clubId}`,
}

const clubsService = {
  async getAllClubs() {
    const response = await useHttp().get(enpoints.clubs)
    return response.data
  },

  async getUsersClub() {
    const response = await useHttp().get(enpoints.usersClubs)
    return response.data
  },

  async handleJoinClub(clubId) {
    const response = await useHttp().post(enpoints.joinClub(clubId))
    return response.data
  },

  async handleLeaveClub(clubId) {
    const response = await useHttp().post(enpoints.leaveClub(clubId))
    return response.data
  },
}

export default clubsService
