import React, { useEffect, useState } from 'react'
import { IMaskInput } from 'react-imask'

import cn from 'classnames'

//validators
import { fieldValidator } from '@/utils/validators/form-validators'

const TextInputField = ({
  required = false,
  type = 'text',
  groupClasses = '',
  inputClasses = '',
  errorClasses = '',
  placeholder = 'Поле',
  minLen = 1,
  maxLen = 31,
  role,
  ...props
}) => {
  const { input, setters } = props.meta
  const [isFocused, setIsFocused] = useState(false)

  const hasError = input.isErrorShown && input.error

  // validating filed requires first touch, to avoid this problem
  useEffect(() => {
    if (input.value === '' && required) setters.setError(fieldValidator(input.value))
  }, [])

  const handleChange = (e) => {
    const value = props.mask ? e : e.target.value
    // console.log(role)

    setters.changeValue(value)

    if (fieldValidator(value, role, required, minLen, maxLen)) {
      setters.setError(fieldValidator(value, role, required, minLen, maxLen))
    } else setters.setError(null)
  }

  return (
    <div className={cn('form__group', groupClasses)}>
      {props.mask ? (
        <IMaskInput
          required={required}
          placeholder={placeholder}
          value={input.value}
          mask={props.mask}
          min={props.min && props.min}
          max={props.max && props.max}
          lazy={!isFocused}
          className={cn(
            'form__input',
            inputClasses,
            hasError && 'error',
            props.disabled && 'disable'
          )}
          type="text"
          disabled={props.disabled}
          onAccept={(e) => {
            if (!props.disabled) handleChange(e)
          }}
          onFocus={() => setIsFocused(true)}
          onBlur={(e) => {
            setIsFocused(false)
            e.target.value.includes('_') && setters.changeValue('')
          }}
        />
      ) : (
        <input
          value={input.value}
          required={required}
          className={cn('form__input', inputClasses, hasError && 'error')}
          type={type}
          placeholder={placeholder}
          onChange={handleChange}
        />
      )}

      {hasError && <span className={cn('form__error', errorClasses)}>{input.error}</span>}
    </div>
  )
}

export default TextInputField
