import { configureStore } from '@reduxjs/toolkit'

//slices
import authSlice from './slices/authSlice'
import uiSlice from './slices/uiSlice'
import profileSlice from './slices/profileSlice'
import clubsSlice from './slices/clubsSlice'
import purchaseSlice from './slices/purchaseSlice'
import economySlice from './slices/economySlice'

export const store = configureStore({
  reducer: {
    authSlice,
    uiSlice,
    profileSlice,
    clubsSlice,
    purchaseSlice,
    economySlice,
  },
})
