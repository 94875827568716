import ContentLoader from 'react-content-loader'

const PieDiagramSkeleton = (props) => {
  return (
    <ContentLoader viewBox="0 0 300 300" height={300} width={300} {...props}>
      <circle cx="150" cy="150" r="150" />
    </ContentLoader>
  )
}

export default PieDiagramSkeleton
