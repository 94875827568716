import React from 'react-dom'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'

import CircleStatus from '@components/shared/icons/CircleStatus'

const UserInfo = () => {
  const { user, error, loading } = useSelector((state) => state.profileSlice)

  const loader = loading && <span>Загрузка...</span>
  const errorMessage = error && error

  return (
    <>
      {loader}
      {errorMessage}
      {!loading && !errorMessage ? (
        <div className={`user-info flex profile-page__user-info`}>
          <div className="user-info__card flex">
            <div className="user-info__card-body">
              <h4 className="user-info__fullname">
                {user?.name} {user?.surname}
              </h4>
              <p className="user-info__email">{user?.email}</p>
            </div>
            <div className="user-info__card-action">
              <Link to="/cabinet/contacts" className="user-info__button-edit flex">
                <span className="user-info__button-edit-text">Редактировать</span>
                <span className="icon user-info__icon-edit"></span>
              </Link>
            </div>
          </div>
          <Link to="/cabinet/small-card" className="bonus-statics ">
            <p className="bonus-statics__number">4905533152300</p>
            <p className="bonus-statics__title">Мои бонусы</p>
            <p className="bonus-statics__bonuses">123</p>
            <p className="bonus-statics__status-wrapper flex">
              <CircleStatus status="active" />
            </p>
          </Link>
        </div>
      ) : null}
    </>
  )
}

export default UserInfo
