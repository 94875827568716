import React from 'react'

// components
import { Chart as ChartJS, ArcElement } from 'chart.js'
import { Pie } from 'react-chartjs-2'

// assets
import '@assets/scss/components/cabinet/diagrams/categoryPieDiagram.scss'

ChartJS.register(ArcElement)

const CategoryPieDiagram = ({ categories }) => {
  const pieDiagramData = {
    labels: '',
    datasets: [
      {
        data: categories ? categories.map(({ precent }) => precent) : [1],
        backgroundColor: categories ? categories.map(({ color }) => color) : ['#E9E9E9'],
        borderColor: 'white',
        borderWidth: categories ? 3 : 0,
      },
    ],
  }

  const pieDiagramOpt = {
    plugins: {
      tooltip: {
        enabled: false,
        xAlign: 'center',
        yAlign: 'center',
      },
      //   legend: {
      //     display: false,
      //   },
    },
  }

  const alwaysShowIooltips = {
    id: 'alwaysShowIooltips',
    afterDraw: (chart, args, options) => {
      const { ctx } = chart
      ctx.save()

      chart.data.datasets.forEach((dataset, i) => {
        chart.getDatasetMeta(i).data.forEach((dataPoint, i) => {
          const { x, y } = dataPoint.tooltipPosition()

          const text = `${chart.data.datasets[0].data[i]}%`

          //text
          ctx.font = `14px Arial`
          ctx.fillStyle = 'white'
          ctx.fillText(text, x - 10, y + 5)
          ctx.restore()
        })
      })
    },
  }

  return (
    <Pie
      data={pieDiagramData}
      options={pieDiagramOpt}
      plugins={categories ? [alwaysShowIooltips] : null}
      width="300px"
      height="300px"
      className="pie-diagram"
    />
  )
}

export default CategoryPieDiagram
