import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { loadDateRangePurchases } from '../../../../store/slices/purchaseSlice'
import { setDateRangeFrom, setDateRangeTo } from '../../../../store/slices/purchaseSlice'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

import styles from './RangeDatepicker.module.scss'

export default function RangeDatepicker() {
  const [endDate, setEndDate] = useState(new Date())
  const [startDate, setStartDate] = useState(new Date().setMonth(new Date().getMonth() - 3))
  const dispatch = useDispatch()

  const onChange = (dates) => {
    const [start, end] = dates
    setStartDate(start)
    setEndDate(end)
    const dataRange = { start: dateTransformer(start), end: dateTransformer(end) }
    end && dispatch(loadDateRangePurchases(dataRange))
  }

  const dateTransformer = (date) => date.toISOString().split('T')[0]

  const CustomInput = React.forwardRef(({ value, onChange, onClick }, ref) => {
    return (
      <div className={`${styles['range-datepicker-wrapper']}`}>
        <input
          className={`${styles['range-datepicker']}`}
          value={value}
          onClick={onClick}
          onChange={(e) => onChange(e.target.value)}
          placeholder="19.12.2022 - 25.12.2022"
          min="19.12.2022"
          ref={ref}
        />
        <button className={`${styles['icon-calendar']} button`} onClick={onClick}></button>
        <button className={`${styles['icon-arrow-down']} button`} onClick={onClick}></button>
      </div>
    )
  })

  return (
    <DatePicker
      dateFormat="dd.MM.yyyy"
      selected={startDate}
      onChange={onChange}
      startDate={startDate}
      endDate={endDate}
      minDate={startDate}
      maxDate={endDate}
      selectsRange
      customInput={<CustomInput />}
    />
  )
}
