import useHttp from '@api'

const enpoint = '/auth/me'

const profileService = {
  getCurrentUser: async () => {
    try {
      const response = await useHttp().get(enpoint)
      return response.data
    } catch (e) {
      return e
    }
  },

  updateCurrentUser: async (id, newUser) => {
    const response = await useHttp().put(`/user/${id}`, newUser)
    return response.data
  },
}

export default profileService
