// hooks
import { useSelector } from 'react-redux'

// components
import EconomyPieDiagram from '@components/cabinet/my-purchases/diagrams/CategoryPieDiagram'
import { Accordion, AccordionBody, AccordionHeader, AccordionItem } from 'react-headless-accordion'
import PieDiagramSkeleton from '@components/skeletons/diagrams/PieDiagramSkeleton'
import PieCategoriesSkeleton from '@components/skeletons/diagrams/PieCategoriesSkeleton'

// utils
import { v4 as uuid } from 'uuid'

const PieDiagramLayout = () => {
  const { purchasesPerDate, loading, error } = useSelector((state) => state.purchaseSlice)
  const categories = purchasesPerDate?.categories

  const renderCategoryList = () => {
    if (categories) {
      const mainCategoryList = categories
        .slice(0, categories.length - 1)
        .map(({ category_name, sum, color }) => {
          return (
            <li className="my-purchases-analysis-page__empty-statics flex" key={uuid()}>
              <span
                className="my-purchases-analysis-page__color-label"
                style={{ backgroundColor: `${color}` }}
              ></span>
              <span className="my-purchases-analysis-page__text">{category_name}</span>
              <p className="my-purchases-analysis-page__empty-statics-right">{sum}</p>
            </li>
          )
        })
      const otherCategoryList = [...categories[4].category_list]
        .sort((a, b) => b.sum - a.sum)
        .map(({ category_name, sum }) => {
          return (
            <li className="my-others-category-accordion__item" key={uuid()}>
              <span className="my-purchases-analysis-page__text">{category_name}</span>
              <p className="my-purchases-analysis-page__empty-statics-right">{sum}</p>
            </li>
          )
        })
      return (
        <>
          <ul className="my-purchases-analysis-page">{mainCategoryList}</ul>
          <Accordion className={'my-others-category-accordion'}>
            <AccordionItem>
              <AccordionHeader>
                <div className={`accordion-title my-others-category-accordion__title`}>
                  <span
                    className="my-purchases-analysis-page__color-label"
                    style={{ backgroundColor: `${categories[4].color}` }}
                  ></span>
                  <span className="my-purchases-analysis-page__text">
                    {categories[4].category_name}
                  </span>
                  <p className="my-purchases-analysis-page__empty-statics-right">
                    {categories[4].sum}
                  </p>
                </div>
              </AccordionHeader>

              <AccordionBody
                as={'ul'}
                className="accordion-body my-others-category-accordion__content"
              >
                {otherCategoryList}
              </AccordionBody>
            </AccordionItem>
          </Accordion>
        </>
      )
    } else {
      return (
        <>
          <div className={`accordion-title my-others-category-accordion__title`}>
            <span
              className="my-purchases-analysis-page__color-label"
              style={{ backgroundColor: `#E9E9E9` }}
            ></span>
            <span className="my-purchases-analysis-page__text">Нет информации</span>
            <p className="my-purchases-analysis-page__empty-statics-right">0</p>
          </div>
        </>
      )
    }
  }

  const loader = loading && <PieDiagramSkeleton />
  const errorMessage = error && error

  return (
    <>
      {errorMessage}

      <div className="my-purchases-analysis-page__empty-chart-wrapper flex">
        <div className="economyPieDiagram">
          {loading ? <PieDiagramSkeleton /> : <EconomyPieDiagram categories={categories} />}
          <div className="economyPieDiagram__dot"></div>
        </div>
        <div className="my-purchases-analysis-page__empty-description-wrapper">
          <p className="my-purchases-analysis-page__empty-description">
            Начните использовать карту SMALL при оплате покупок в наших магазинах, чтобы следить за
            своими тратами
          </p>
          {loading ? <PieCategoriesSkeleton /> : renderCategoryList()}
        </div>
      </div>
    </>
  )
}

export default PieDiagramLayout
