import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import profileService from '@/services/profileService'

//init
const name = 'profileSlice'
const initialState = {
  user: null,
  hasCard: false,
  isBirth: false,
  error: null,
  loading: false,
  updateLoading: false,
  showSuccessModal: false,
}

//slice
const profileSlice = createSlice({
  name,
  initialState,
  reducers: {
    //fake action
    setUserHasCard: (state, action) => {
      state.hasCard = action.payload
    },

    setIsBirth: (state, action) => {
      state.isBirth = action.payload
    },

    setShowSuccessModal: (state, action) => {
      state.showSuccessModal = action.payload
    },
  },
  extraReducers: (builder) => {
    // GET_USER
    builder.addCase(getUserData.pending, (state) => {
      state.loading = true
      state.error = null
    })
    builder.addCase(getUserData.fulfilled, (state, action) => {
      state.user = action.payload
      state.hasCard = action.payload.card_number ? true : false
      state.loading = false
    })
    builder.addCase(getUserData.rejected, (state, action) => {
      state.error = action.payload
      state.loading = false
    })

    // UPDATE_USER
    builder.addCase(updateUserData.pending, (state) => {
      state.updateLoading = true
      state.error = null
    })
    builder.addCase(updateUserData.fulfilled, (state, action) => {
      state.updateLoading = false
      state.user = action.payload
      state.hasCard = action.payload.card_number ? true : false
      state.showSuccessModal = true
    })
    builder.addCase(updateUserData.rejected, (state, action) => {
      state.error = action.payload
      state.updateLoading = false
    })
  },
})

//thunks
export const getUserData = createAsyncThunk(
  `${name}/getUserData`,
  async (_, { rejectWithValue, dispatch }) => {
    const response = await profileService.getCurrentUser()
    if (response.message) {
      console.error(response)
      return rejectWithValue('Не удалось получить пользователя. Повторите попытку позже')
    }
    return response.data
  }
)

export const updateUserData = createAsyncThunk(
  `${name}/updateUserData`,
  async ({ id, newUser }, { rejectWithValue }) => {
    try {
      const response = await profileService.updateCurrentUser(id, newUser)
      return response.data
    } catch (e) {
      console.error(e)
      const errorMessage = e.response.data?.message
        ? rejectWithValue(e.response.data.message)
        : rejectWithValue('Не удалось обновить пользователя. Повторите попытку позже')
      return errorMessage
    }
  }
)

//actions
export const { setUserHasCard, setIsBirth, setShowSuccessModal } = profileSlice.actions

export default profileSlice.reducer
