import React from "react"
import cn from "classnames"

//assets
import PrductImage from "@assets/images/temp/product.png"

const FavoriteProductCard = ({ onClick, isSelected }) => {
  const buttonText = isSelected ? "Товар уже выбран" : "Выбрать"
  const buttonClassName = isSelected ? "button--red-light" : "button--red"

  return (
    <div className={"favorite-product-card"}>
      <h5 className="favorite-product-card__title product-title--grey">АЗОВСКАЯ Мармелад, 200 г</h5>
      <div className={"favorite-product-card__image"}>
        <img src={PrductImage} />
      </div>
      <button
        className={cn("favorite-product-card__button button", buttonClassName)}
        onClick={onClick}
      >
        {buttonText}
      </button>
    </div>
  )
}

export default FavoriteProductCard
