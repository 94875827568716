import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// components
import { Link } from 'react-router-dom'

// actions
import { joinClub, leaveClub } from '@/store/slices/clubsSlice'

// libs
import cn from 'classnames'

const ClubCardLarge = ({ id, setId, image, title, descr, isJoined }) => {
  const { usersClubs, loading } = useSelector((state) => state.clubsSlice)
  const dispatch = useDispatch()

  const isClubsLimited = usersClubs && usersClubs.length === 3

  const joinedContent =
    isClubsLimited && isJoined
      ? 'Покинуть клуб'
      : isJoined
      ? 'Вы состоите в клубе'
      : 'Вступить в клуб'

  const isBtnDisabled = (isClubsLimited && !isJoined) || (isJoined && !isClubsLimited)

  const handleJoinClub = (clubSet) => {
    if (isJoined) {
      dispatch(leaveClub(clubSet))
    } else {
      dispatch(joinClub(clubSet))
    }
  }

  return (
    <div className={'club-card-large'}>
      <div className={'club-card-large__image-wrapper'}>
        <img className={'club-card-large__image'} src={image} />
      </div>
      <div className={'club-card-large__desc-wrapper flex'}>
        <h4 className={'club-card-large__title'}>{title}</h4>
        <p className={'club-card-large__desc-text'}>{descr}</p>
        <div className={'club-card-large__actions flex'}>
          <Link to={`${id}`} className={'button button-readmore'}>
            Подробнее о клубе
          </Link>
          <button
            className={cn('button club-card-large__button button--red')}
            disabled={isBtnDisabled}
            onClick={() => handleJoinClub(setId)}
          >
            {joinedContent}
          </button>
        </div>
      </div>
    </div>
  )
}

export default ClubCardLarge
