import React from 'react'
import { Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

// Import Swiper styles
import 'swiper/css'
import 'swiper/css/pagination'

import slideImage from '@assets/images/temp/01.jpg'

const PromoSlider = () => {
  return (
    <div className="profile-page__slider-wrapper">
      <Swiper
        className="promo-slider"
        modules={[Pagination]}
        spaceBetween={10}
        slidesPerView={1}
        pagination={{ clickable: true }}
        scrollbar={{ draggable: true }}
        // onSlideChange={() => console.log("slide change")}
        // onSwiper={(swiper) => console.log(swiper)}
      >
        <SwiperSlide>
          <img src={slideImage} />
        </SwiperSlide>
        <SwiperSlide>
          <img src={slideImage} />
        </SwiperSlide>
        <SwiperSlide>
          <img src={slideImage} />
        </SwiperSlide>
      </Swiper>
    </div>
  )
}

export default PromoSlider
