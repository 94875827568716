import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import authAPI from '@/services/authService'

//init
const name = 'authSlice'
const initialState = {
  loading: false,
  isLoggedIn: false,
  loginError: null,
  registerError: null,
}

//slice
export const authSlice = createSlice({
  name,
  initialState,
  reducers: {
    logOut: (state) => {
      state.user = null
      state.isLoggedIn = false
      localStorage.removeItem('token')
    },
    setIsLoggedIn: (state, action) => {
      state.isLoggedIn = action.payload
    },
  },
  extraReducers: (builder) => {
    //REGISTER
    builder.addCase(register.pending, (state) => {
      state.loading = true
      state.loginError = null
    })
    builder.addCase(register.fulfilled, (state, action) => {
      state.loading = false
      state.error = null
      state.user = action.payload
      state.isLoggedIn = true
    })
    builder.addCase(register.rejected, (state, action) => {
      state.loading = false
      state.registerError = action.payload
    })

    // LOGIN
    builder.addCase(login.pending, (state) => {
      state.loading = true
      state.registerError = null
    })
    builder.addCase(login.fulfilled, (state, action) => {
      state.loginError = null
      state.loading = false
      state.user = action.payload
      state.isLoggedIn = true
    })
    builder.addCase(login.rejected, (state, action) => {
      state.loading = false
      state.loginError = action.payload
    })
  },
})

//thunks
export const register = createAsyncThunk(
  `${name}/register`,
  async (userData, { rejectWithValue }) => {
    const response = await authAPI.handleRegister(userData)

    if (response.message) {
      console.error(response)
      return rejectWithValue(response.response.data.errors.email[0])
    }

    localStorage.setItem('token', response.data.token)
    return response.data.user
  }
)

export const login = createAsyncThunk(`${name}/login`, async (userData, { rejectWithValue }) => {
  const response = await authAPI.handleLogin(userData)

  if (response.message) {
    console.error(response)
    return rejectWithValue(response.response.data.message)
  }

  localStorage.setItem('token', response.data.token)
  return response.data
})

//actions
export const { logOut, setIsLoggedIn } = authSlice.actions

export default authSlice.reducer
