import useHttp from '@api'

const enpoints = {
  register: '/auth/register',
  login: '/auth/login',
}

const authService = {
  handleRegister: async (data) => {
    try {
      const response = await useHttp().post(enpoints.register, data)
      console.log(response)
      return response.data
    } catch (e) {
      return e
    }
  },

  handleLogin: async (data) => {
    try {
      const response = await useHttp().post(enpoints.login, data)
      return response.data
    } catch (e) {
      return e
    }
  },
}

export default authService
