// hooks
import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'

// actions
import { setIsLoggedIn } from '../store/slices/authSlice'
import { getUserData } from '@/store/slices/profileSlice'
import { setIsBirth } from '@/store/slices/profileSlice'
import { loadDateRangePurchases } from '@/store/slices/purchaseSlice'
import { loadEconomyForThreeMonth } from '@/store/slices/economySlice'
import { getUserClubs, loadClubs } from '@/store/slices/clubsSlice'

// components
import Header from '@components/shared/Header'
import Breadcrumb from '@components/shared/Breadcrumb'
import CabinetNavigation from '@components/cabinet/CabinetNavigation'

// utils
import { isTodayBirth } from '../utils/hooks/isTodayBirth'

const MainCabinet = () => {
  const isVisibleMenu = useSelector((state) => state.uiSlice.isVisibleMenu)
  const userBirthDate = useSelector((state) => state.profileSlice?.user?.birth_date)

  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const localToken = localStorage.getItem('token')

  // Set inital data about user in store
  useEffect(() => {
    if (localToken) {
      dispatch(getUserData())
      dispatch(loadDateRangePurchases())
      dispatch(loadEconomyForThreeMonth())
      dispatch(setIsLoggedIn(true))
      dispatch(getUserClubs())
      dispatch(loadClubs())
      navigate(`${location.pathname}`)
    } else {
      navigate('/login')
    }
  }, [localToken])

  // Check user birthday, if it`s true - set true in store
  useEffect(() => {
    if (userBirthDate && isTodayBirth(userBirthDate)) {
      dispatch(setIsBirth(true))
    }
  }, [userBirthDate])

  return (
    <div className="layout">
      <Header />
      <div className="container">
        <div className="wrapper">
          <Breadcrumb />
          <div className="cabinet flex">
            <div className={isVisibleMenu ? 'aside mobile-aside' : 'aside'}>
              <CabinetNavigation />
            </div>
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  )
}

export default MainCabinet
