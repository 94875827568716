//components
import PieDiagramLayout from '@components/cabinet/my-purchases/analysis/PieDiagramLayout'
import BarDiagramLayout from '@components/cabinet/my-purchases/analysis/BarDiagramLayout'

const MyPurcharesAnalysisPage = () => {
  return (
    <div className="my-purchases-analysis-page">
      <PieDiagramLayout />
      <BarDiagramLayout />
    </div>
  )
}

export default MyPurcharesAnalysisPage
