import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import purchasesService from '../../services/purchasesService'

//init
const name = 'economySlice'
const initialState = {
  economyForThreeMonth: null,
  dateRangeFrom: new Date(new Date().setMonth(new Date().getMonth() - 2))
    .toISOString()
    .split('T')[0],
  dateRangeTo: new Date().toISOString().split('T')[0],
  currentMonth: {},
  loading: false,
  error: null,
}

//slice
export const economySlice = createSlice({
  name,
  initialState,
  reducers: {
    setCurrentMonth: (state, action) => {
      state.currentMonth = action.payload
    },
  },
  extraReducers: (builder) => {
    //loadEconomy for 3 month
    builder.addCase(loadEconomyForThreeMonth.pending, (state) => {
      state.loading = true
      state.error = null
    })
    builder.addCase(loadEconomyForThreeMonth.fulfilled, (state, action) => {
      state.loading = false
      state.economyForThreeMonth = action.payload.economy ? action.payload.economy : action.payload
      state.currentMonth =
        action.payload === 'string'
          ? action.payload
          : action.payload.economy[action.payload.economy.length - 1]
    })
    builder.addCase(loadEconomyForThreeMonth.rejected, (state, action) => {
      state.status = false
      state.error = action.payload
    })
  },
})

// thunks
export const loadEconomyForThreeMonth = createAsyncThunk(
  `${name}/loadEconomyForThreeMonth`,
  // { start: initialState.dateRangeFrom, end: initialState.dateRangeTo }
  // { start: '2022-10-05', end: '2022-12-05' }
  async (_, { rejectWithValue }) => {
    const start = '2022-10-05'
    const end = '2022-12-05'
    // const start = initialState.dateRangeFrom
    // const end = initialState.dateRangeTo
    const response = await purchasesService.getAllPurchase(start, end)

    if (response.message) {
      console.error(response)
      return rejectWithValue(
        'При загрузке данных произошла ошибка. Пожалуйста, попробуйте повторить позже.'
      )
    }
    return response
  }
)

export const { setCurrentMonth } = economySlice.actions

export default economySlice.reducer
