import React, { useState } from "react"

//components
import SuccessfulCompletion from "@components/cabinet/popup-content/SuccessfulCompletion"

const ReissueSmallCard = ({ onClose }) => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)

  const handleReissueNewCard = () => {
    //submit implementation
    setIsSubmitting(true)
    setTimeout(() => {
      setIsSubmitting(false)
      setIsSuccess(true)
    }, 2500)
  }

  return (
    <>
      {!isSuccess ? (
        <div className={"open-new-small-card__content flex"}>
          <h4 className={"open-new-small-card__title"}>
            При перевыпуске карты, ваша текущая карта заблокируется. Вы уверены, что хотите
            выпустить новую?
          </h4>
          <div className={"pop-up__actions flex"}>
            <button
              type="button"
              className={"open-new-small-card__button-open button button--red"}
              disabled={isSubmitting}
              onClick={handleReissueNewCard}
            >
              Да
            </button>
            <button
              type="button"
              className={"open-new-small-card__button-cancel button--outlined "}
              disabled={isSubmitting}
              onClick={onClose}
            >
              Нет
            </button>
          </div>
        </div>
      ) : (
        <SuccessfulCompletion text="Ваша карта перевыпущена!" />
      )}
    </>
  )
}

export default ReissueSmallCard
