const DefaultBanner = () => {
  return (
    <div className="bonus-coupon-page__header ">
      <p className="bonus-coupon-page__label">Мой баланс</p>
      <p className="bonus-coupon-page__bonus">50 Бонусов</p>
      <p className="bonus-coupon-page__message">1 бонус = 1 тенге</p>
    </div>
  )
}

export default DefaultBanner
