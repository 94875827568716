// hooks
import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import useFormField from '@/utils/hooks/useFormField'

// actions
import { updateUserData } from '@/store/slices/profileSlice'
import { setIsBirth } from '@/store/slices/profileSlice'
import { setShowSuccessModal } from '@/store/slices/profileSlice'

// components
import PopUp from '@components/shared/popup/PopUp'
import ChangePassword from '@components/cabinet/popup-content/ChangePassword'
import SuccessfulCompletion from '@components/cabinet/popup-content/SuccessfulCompletion'
import TextInputField from '@components/shared/form/TextInputField'

// utils
import { isTodayBirth } from '@/utils/hooks/isTodayBirth'
import { isSubmitBtnDisabled } from './isSubmitBtnDisabled'

// styles
import styles from './ContactsForm.module.scss'

const ContactsForm = () => {
  const { user, error, loading, updateLoading, showSuccessModal } = useSelector(
    (state) => state.profileSlice
  )

  const userName = useFormField('')
  const userSurname = useFormField('')
  const userPhone = useFormField('')
  const userEmail = useFormField('')
  const userBirth = useFormField('')
  const userGender = useFormField(null)

  const [isChangePassPopUpOpen, setChangePassPopUpState] = useState(false)
  const [disableBirthField, setDisableBirthField] = useState(false)
  const dispatch = useDispatch()

  useEffect(() => {
    if (user?.birth_date) setDisableBirthField(true)
    else setDisableBirthField(false)

    userName.setters.changeValue(user?.name ? user.name : '')
    userSurname.setters.changeValue(user?.surname ? user.surname : '')
    userPhone.setters.changeValue(user?.phone ? user.phone : '')
    userEmail.setters.changeValue(user?.email ? user.email : '')
    userBirth.setters.changeValue(user?.birth_date ? user.birth_date : '')
    userGender.setters.changeValue(user?.gender)
  }, [user])

  // Form fields

  const fieldsObj = {
    userName,
    userSurname,
    userPhone,
    userEmail,
    userBirth,
    userGender,
  }

  const ChangePasswordPopUp = () => {
    return (
      <PopUp
        onClose={(e) => {
          //   console.log(e)
          setChangePassPopUpState(false)
        }}
      >
        <ChangePassword />
      </PopUp>
    )
  }

  const SuccessPopUp = () => {
    return (
      <PopUp onClose={() => dispatch(setShowSuccessModal(false))}>
        <SuccessfulCompletion text="Данные успешно обновлены" />
      </PopUp>
    )
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    const id = user.id

    // Check user birthday, if it`s true - set true in store
    if (userBirth && isTodayBirth(userBirth.input.value)) {
      dispatch(setIsBirth(true))
    } else {
      dispatch(setIsBirth(false))
    }

    // create a new user data object
    const newUser = {
      name: userName.input.value,
      surname: userSurname.input.value,
      email: userEmail.input.value,
      phone: userPhone.input.value,
      gender: userGender.input.value,
      birth_date: userBirth.input.value,
    }

    // set validation errors
    if (Object.values(fieldsObj).some((field) => field.input.error)) {
      Object.values(fieldsObj).forEach((field) => field.setters.showError())
      return
    }

    dispatch(updateUserData({ id, newUser }))
  }

  return (
    <>
      <div className={`${styles.wrapper} flex`}>
        <h2 className={styles.title}>Контактная информация</h2>

        {loading && 'Загрузка...'}
        {error && <p style={{ marginBottom: '10px', color: '#d80d1b' }}>{error}</p>}
        {user ? (
          <form className={`${styles.form}`} onSubmit={handleSubmit}>
            {/* Name */}
            <TextInputField
              meta={userName}
              type="text"
              role="name"
              placeholder="Имя"
              required={true}
            />

            {/* Surname */}
            <TextInputField
              meta={userSurname}
              type="text"
              role="surname"
              placeholder="Фамилия"
              required={false}
            />

            {/* Phone */}
            <TextInputField
              meta={userPhone}
              type="text"
              role="phone"
              placeholder="Номер телефона"
              required={false}
              mask="+7 (000) 000-00-00"
            />

            {/* Email */}
            <TextInputField
              meta={userEmail}
              type="text"
              role="email"
              placeholder="Email"
              required={true}
            />

            {/* Birth */}
            <TextInputField
              meta={userBirth}
              type="text"
              role="birth"
              placeholder="Дата рождения (дд/мм/гг)"
              required={false}
              mask={Date}
              disabled={disableBirthField}
              min={new Date(new Date().getFullYear() - 100, 0, 1)}
              max={new Date(new Date().getFullYear() + 1, 0, 1)}
            />

            <div className={`${styles['form-actions']} flex`}>
              <h4 className={styles['form-actions__title']}>Укажите свой пол</h4>
              <div className={`${styles['checkboxes-wrapper']} flex`}>
                <div className="custom-checkbox__wrapper">
                  <input
                    className="custom-checkbox__input"
                    type="radio"
                    name="gender"
                    id="male"
                    checked={userGender.input.value === true}
                    // checked={userGender.input.value === true}
                    onChange={() => userGender.setters.changeValue(true)}
                  />
                  <label className="custom-checkbox__label" htmlFor="male">
                    Мужской
                  </label>
                </div>
                <div className="custom-checkbox__wrapper">
                  <input
                    className="custom-checkbox__input"
                    type="radio"
                    name="gender"
                    id="female"
                    checked={userGender.input.value === false}
                    onChange={() => userGender.setters.changeValue(false)}
                  />
                  <label className="custom-checkbox__label" htmlFor="female">
                    Женский
                  </label>
                </div>
              </div>
            </div>
            <div className={`form-row form-row_change-password-btn`}>
              <p
                className={`button ${styles['button-change-password']}`}
                onClick={() => setChangePassPopUpState(true)}
              >
                Изменить пароль
              </p>
            </div>
            <button
              className={`${styles['button-submit']}`}
              onClick={handleSubmit}
              disabled={isSubmitBtnDisabled(user, fieldsObj) || updateLoading}
            >
              Сохранить
            </button>
          </form>
        ) : null}
      </div>
      {isChangePassPopUpOpen && <ChangePasswordPopUp />}
      {showSuccessModal && <SuccessPopUp />}
    </>
  )
}

export default ContactsForm
