import React from 'react'
import { useRef } from 'react'
import { createPortal } from 'react-dom'

import styles from './CheckPopUp.module.scss'

const CheckPopUp = ({ children, onClose }) => {
  const overlayRef = useRef()
  const closeHandler = (e) => {
    e.target === overlayRef.current && onClose()
  }

  return createPortal(
    <div className={styles.overlay} onClick={(e) => closeHandler(e)} tabIndex={0} ref={overlayRef}>
      <div className={styles.popup} tabIndex={0}>
        <div className={`${styles.body} flex`}>{children}</div>
      </div>
    </div>,
    document.body
  )
}

export default CheckPopUp
