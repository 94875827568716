// hooks
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'

//components
import PromoSlider from '@components/cabinet/sliders/PromoSlider'
import UserInfo from '@components/cabinet/profile/UserInfo'
import PurchaseAnalysisCard from '@components/cabinet/profile/PurchaseAnalysisCard'
import FavoriteCardDefault from '@components/cabinet/profile/FavoriteCardDefault'
import ClubCardSmall from '@components/cabinet/club/ClubCardSmall'
import EmptyClubsBanner from '@components/cabinet/club/EmptyClubsBanner'
import SmallCardInactive from '@components/cabinet/small-card/SmallCardInactive'

const ProfilePage = () => {
  const { usersClubs } = useSelector((state) => state.clubsSlice)
  const { hasCard, loading } = useSelector((state) => state.profileSlice)

  const renderUsersClubs = (clubs) => {
    return clubs?.map(({ title_ru, id, img }) => (
      <ClubCardSmall
        className="profile-page__club-item"
        backgroundColor="#91D1FF"
        key={id}
        title={title_ru}
        img={img}
      />
    ))
  }

  const loader = loading && 'Загрузка...'
  const usersClubContent = usersClubs?.length ? renderUsersClubs(usersClubs) : <EmptyClubsBanner />
  const withoutCardView = !loading && !hasCard ? <SmallCardInactive /> : null
  const withCardView =
    !loading && hasCard ? (
      <div className="profile-page__content grid">
        <UserInfo />
        <PurchaseAnalysisCard />
        <FavoriteCardDefault className="profile-page__favorite-card" />

        <div className="profile-page__club-list-wrapper">
          <h4 className="profile-page__club-list-title">Мои клубы</h4>
          <div className="profile-page__club-list flex">{usersClubContent}</div>
        </div>
      </div>
    ) : null

  return (
    <div className="profile-page">
      <PromoSlider />
      {loader}
      {withCardView}
      {withoutCardView}
    </div>
  )
}

export default ProfilePage
