import React from 'react'
import { useSelector } from 'react-redux'

//components
import SmallCardActive from '@components/cabinet/small-card/SmallCardActive'
import SmallCardInactive from '@components/cabinet/small-card/SmallCardInactive'

const SmallCardPage = () => {
  const { hasCard, loading, error } = useSelector((state) => state.profileSlice)

  const showActiveCardComponent = !loading && !error && hasCard
  const showInActiveCardComponent = !loading && !error && !hasCard
  const loader = <span style={{ color: 'black' }}>Загрузка...</span>

  return (
    <div className="small-card-page flex">
      <h2 className="small-card-page__title">Карта Small</h2>
      {loading && loader}
      {error && error}
      {showActiveCardComponent && <SmallCardActive />}
      {showInActiveCardComponent && <SmallCardInactive />}
    </div>
  )
}

export default SmallCardPage
