import ContentLoader from 'react-content-loader'

const BarDiagramSkeleton = (props) => {
  return (
    <ContentLoader width={275} height={250} viewBox="0 0 275 250" {...props}>
      <rect x="0" y="160" rx="0" ry="0" style={{ width: '65px', height: '100%' }} />
      <rect x="85" y="145" rx="0" ry="0" style={{ width: '65px', height: '100%' }} />
      <rect x="175" y="126" rx="0" ry="0" style={{ width: '65px', height: '100%' }} />
    </ContentLoader>
  )
}

export default BarDiagramSkeleton
