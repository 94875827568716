import React from "react"

//assets
import successIcon from "@assets/icons/success.svg"

const SuccessfulCompletion = ({ text }) => {
  return (
    <div className="successful-completion">
      <div className="successful-completion__image">
        <img src={successIcon} />
      </div>
      <h4 className="successful-completion__text">
        <span>Поздравляем!</span>
        <br />
        <span>{text}</span>
      </h4>
    </div>
  )
}

export default SuccessfulCompletion
