export const isSubmitBtnDisabled = (user, fields) => {
  const { userName, userSurname, userPhone, userEmail, userBirth, userGender } = fields

  // disabled indicator. Empty field maybe null on server, then field on the client will be an ampty srting
  const isDisabled =
    user?.name === userName.input.value &&
    //
    (user?.surname === userSurname.input.value ||
      (user?.surname === null && userSurname.input.value === '')) &&
    //
    (user?.phone === userPhone.input.value ||
      (user?.phone === null && userPhone.input.value === '')) &&
    //
    user?.email === userEmail.input.value &&
    //
    (user?.birth_date === userBirth.input.value ||
      (user?.birth_date === null && userBirth.input.value === '')) &&
    //
    (user?.gender === userGender.input.value ||
      (user?.gender === null && userGender.input.value === ''))

  return isDisabled
}
