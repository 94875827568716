import '../../../assets/scss/components/cabinet/pop-ups/transactions-check.scss'

const TransactionsCheck = ({ ofd, time, adress, sum }) => {
  const purchaseDate = time.split(' ')[0]
  const purchaseTime = time.split(' ')[1]
  console.log(purchaseTime)
  return (
    <div className={'transactionCheck'}>
      <h2 className="transactionCheck__title">Чек</h2>
      <h2 className="transactionCheck__title-sum">{sum} тг</h2>
      <hr />
      <ul className="transactionCheck__list">
        <li className="transactionCheck__list-item">
          <span className="transactionCheck__list-item-title">№ квитанции </span>
          <span className="transactionCheck__list-item-descr">{ofd}</span>
        </li>
        <li className="transactionCheck__list-item">
          <span className="transactionCheck__list-item-title">Дата и время</span>
          <div style={{ display: 'flex', alignItems: 'flex-end', flexDirection: 'column' }}>
            <span className="transactionCheck__list-item-descr">{purchaseDate}</span>
            <span className="transactionCheck__list-item-descr">{purchaseTime}</span>
          </div>
        </li>
        <li className="transactionCheck__list-item">
          <span className="transactionCheck__list-item-title">Филиал</span>
          <span
            className="transactionCheck__list-item-descr"
            style={{ width: '50%', textAlign: 'right' }}
          >
            {adress}
          </span>
        </li>
      </ul>
    </div>
  )
}

export default TransactionsCheck
