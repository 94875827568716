// components
import { Link } from 'react-router-dom'

// assets
import '@assets/scss/components/cabinet/club/empty-clubs-banner.scss'

const EmptyClubsBanner = () => {
  return (
    <div className="emptyClubBanner">
      <Link to="/cabinet/clubs" className="emptyClubBanner__joinBtn">
        ВСТУПИТЕ В КЛУБ
      </Link>
      <p className="emptyClubBanner__descr">
        для участия в закрытых <span>распродажах</span>
      </p>
    </div>
  )
}

export default EmptyClubsBanner
