import { createSlice } from '@reduxjs/toolkit'

//init
const name = 'uiSlice'
const initialState = {
  isVisibleMenu: false,
  isOpenNewSmallCardPopup: false,
  isOpenFavoriteProductList: false,
  isOpenAuthForm: false,
}

//slice
export const uiSlice = createSlice({
  name,
  initialState,
  reducers: {
    toggleMenu: (state) => {
      state.isVisibleMenu = !state.isVisibleMenu
    },
    toggleOpenNewSmallCardPopup: (state) => {
      state.isOpenNewSmallCardPopup = !state.isOpenNewSmallCardPopup
    },
    toggleIsOpenFavoriteProductList: (state) => {
      state.isOpenFavoriteProductList = !state.isOpenFavoriteProductList
    },
    toggleIsOpenAuthForm: (state, action) => {
      if (action.payload === undefined) {
        state.isOpenAuthForm = !state.isOpenAuthForm
        return
      }
      state.isOpenAuthForm = action.payload
    },
  },
})

//actions
export const {
  toggleMenu,
  toggleOpenNewSmallCardPopup,
  toggleIsOpenFavoriteProductList,
  toggleIsOpenAuthForm,
} = uiSlice.actions

export default uiSlice.reducer
