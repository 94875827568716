//assets
import GooglePlayImage from '@assets/images/google.svg'
import AppStoreImage from '@assets/images/apple.svg'

const Marketplaces = () => {
  return (
    <div className="small-card-page__marketplaces">
      <a href="https://google.ru" className="marketplace small-card-page__market-google">
        <img src={GooglePlayImage} />
      </a>
      <a href="https://google.ru" className="marketplace small-card-page__market-apple">
        <img src={AppStoreImage} />
      </a>
    </div>
  )
}

export default Marketplaces
