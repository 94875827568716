import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import smallCardService from '../../../services/smallCardService'

//store
import { setUserHasCard } from '@/store/slices/profileSlice'

//components
import SuccessfulCompletion from '@components/cabinet/popup-content/SuccessfulCompletion'

const OpenNewSmallCard = ({ onClose }) => {
  const dispatch = useDispatch()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)

  const handleOpenNewCard = () => {
    //submit implementation
    setIsSubmitting(true)
    setTimeout(() => {
      setIsSubmitting(false)
      setIsSuccess(true)
      dispatch(setUserHasCard(true))
    }, 2500)
  }

  return (
    <>
      {!isSuccess ? (
        <div className={'open-new-small-card__content flex'}>
          <h4 className={'open-new-small-card__title'}>Открываем для вас карту SMALL ?</h4>
          <div className={'pop-up__actions flex'}>
            <button
              type="button"
              className={'open-new-small-card__button-open button button--red'}
              disabled={isSubmitting}
              onClick={handleOpenNewCard}
            >
              Да
            </button>
            <button
              type="button"
              className={'open-new-small-card__button-cancel button--outlined '}
              disabled={isSubmitting}
              onClick={onClose}
            >
              Нет
            </button>
          </div>
        </div>
      ) : (
        <SuccessfulCompletion text="Ваша карта успешно открыта!" />
      )}
    </>
  )
}

export default OpenNewSmallCard
