/* eslint-disable no-undef */
import axios from 'axios'

const useHttp = () => {
  const token = localStorage.getItem('token')

  const baseURL = process.env.REACT_APP_BASE_API_URL
  const headers = {}

  if (token) {
    headers.Authorization = `Bearer ${token}`
  }

  return axios.create({
    baseURL,
    headers,
  })
}

export { useHttp }
