import React from "react"
import cn from "classnames"

const CheckboxInputField = ({
  children,
  value,
  id,
  onChange,
  groupClasses = "",
  inputClasses = "",
  labelClasses = "",
}) => {
  return (
    <div>
      <div className={cn("custom-checkbox__wrapper", groupClasses)}>
        <input
          value={value}
          className={cn("custom-checkbox__input", inputClasses)}
          type="checkbox"
          id={id}
          onChange={onChange}
        />
        <label className={cn("custom-checkbox__label", labelClasses)} htmlFor={id}>
          {children}
        </label>
      </div>
    </div>
  )
}

export default CheckboxInputField
