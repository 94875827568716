import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import purchasesService from '../../services/purchasesService'

//init
const name = 'purchasesSlice'
const initialState = {
  purchasesPerDate: null,
  dateRangeFrom: new Date(new Date().setMonth(new Date().getMonth() - 3))
    .toISOString()
    .split('T')[0],
  dateRangeTo: new Date().toISOString().split('T')[0],
  currentMonth: {},
  loading: false,
  error: null,
}

//slice
export const purchasesSlice = createSlice({
  name,
  initialState,
  reducers: {
    setDateRangeFrom: (state, action) => {
      state.dateRangeFrom = action.payload
    },
    setDateRangeTo: (state, action) => {
      state.dateRangeTo = action.payload
    },
  },
  extraReducers: (builder) => {
    //loadPurchase from date range
    builder.addCase(loadDateRangePurchases.pending, (state) => {
      state.loading = true
      state.error = null
    })
    builder.addCase(loadDateRangePurchases.fulfilled, (state, action) => {
      state.loading = false
      state.purchasesPerDate = action.payload
    })
    builder.addCase(loadDateRangePurchases.rejected, (state, action) => {
      state.status = false
      state.error = action.payload
    })
  },
})

// thunks
export const loadDateRangePurchases = createAsyncThunk(
  `${name}/loadDateRangePurchases`,
  // { start: initialState.dateRangeFrom, end: initialState.dateRangeTo }
  // { start: '2022-10-05', end: '2022-12-05' }
  async (
    dateRange = { start: initialState.dateRangeFrom, end: initialState.dateRangeTo },
    { rejectWithValue }
  ) => {
    const { start, end } = dateRange
    const response = await purchasesService.getAllPurchase(start, end)
    if (response.message) {
      console.error(response)
      return rejectWithValue(
        `При загрузке данных произошла ошибка. Пожалуйста, попробуйте повторить позже.`
      )
    }
    // console.log('loadDateRangePurchases', response)
    return response
  }
)

//actions
export const { setDateRangeFrom, setDateRangeTo } = purchasesSlice.actions

export default purchasesSlice.reducer
