import ContentLoader from 'react-content-loader'

const DonatDiagramSkeleton = (props) => {
  return (
    <ContentLoader
      speed={2}
      width={250}
      height={201}
      viewBox="0 0 250 201"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
      {...props}
    >
      <circle cx="80" cy="65" r="65" />
      <circle cx="146" cy="200" r="2" />
      <circle cx="10" cy="155" r="10" />
      <rect x="35" y="145" rx="0" ry="0" width="200" height="20" />
      <circle cx="10" cy="190" r="10" />
      <rect x="35" y="182" rx="0" ry="0" width="200" height="20" />
    </ContentLoader>
  )
  //   return (
  //     <ContentLoader viewBox="0 0 400 150" style={{ width: '100%', height: '201px' }} {...props}>
  //       <circle cx="10" cy="20" r="8" />
  //       <rect x="25" y="15" rx="5" ry="5" style={{ width: '100%', height: '10px' }} />
  //       <circle cx="10" cy="50" r="8" />
  //       <rect x="25" y="45" rx="5" ry="5" style={{ width: '100%', height: '10px' }} />
  //     </ContentLoader>
  //   )
}

export default DonatDiagramSkeleton
