// hooks
import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import useFormField from '@/utils/hooks/useFormField'

// actions
import { register } from '@/store/slices/authSlice'
import { toggleIsOpenAuthForm } from '@/store/slices/uiSlice'

// components
import TextInputField from '@components/shared/form/TextInputField'
// import PhoneNumberInputField from '@components/shared/form/PhoneNumberInputField'
import PasswordInputField from '@components/shared/form/PasswordInputField'
import CheckboxInputField from '@components/shared/form/CheckboxInputField'
import AuthActions from './AuthActions'

const RegisterForm = ({ changeFormType }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  // state
  //   const [ownSmallCard, setOwnSmallCard] = useState(false)
  const [confirmAgreement, setConfirmAgreement] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)

  //Selectors
  const { registerError, loading, isLoggedIn } = useSelector((state) => state.authSlice)

  // fields
  const name = useFormField('')
  const email = useFormField('')
  //   const phone = useFormField('')
  const password = useFormField('')
  const confirmPassword = useFormField('')
  //   const cardNumber = useFormField('')
  const formFields = {
    name,
    email,
    // phone,
    password,
    confirmPassword,
  }

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(toggleIsOpenAuthForm(false))
      navigate('/cabinet/profile')
    }
  }, [isLoggedIn])

  // handlers
  //   const toggleOwnSmallCard = () => {
  //     setOwnSmallCard((old) => !old)
  //   }
  const toggleConfirmAgreement = () => {
    setConfirmAgreement((old) => !old)
  }

  const handleSubmitForm = async (e) => {
    e.preventDefault()

    //validate form fields before sumbit
    if (Object.values(formFields).some((field) => field.input.error)) {
      Object.values(formFields).forEach((field) => field.setters.showError())
      return
    }

    if (password.input.value !== confirmPassword.input.value) {
      confirmPassword.setters.setError('Пароли не совпадают!')
      confirmPassword.setters.showError()
      return
    }

    //submit implementation
    setIsSubmitting(true)
    dispatch(
      register({
        name: name.input.value,
        email: email.input.value,
        // phone: phone.input.value,
        password: password.input.value,
      })
    )

    setIsSubmitting(false)
  }

  return (
    <form className={'auth-form'} onSubmit={handleSubmitForm}>
      <h4 className={'auth-form__title'}>Регистрация</h4>
      <div className={'auth-form__body auth-register-form__body'}>
        {/* Name */}
        <TextInputField meta={name} type="text" role="name" placeholder="Имя" required />
        {/* Email */}
        <TextInputField meta={email} type="email" role="email" placeholder="Email" required />
        {/* Login */}
        {/* <PhoneNumberInputField meta={phone} type="text" placeholder="Номер телефона" /> */}
        {/* Password */}
        <PasswordInputField meta={password} placeholder="Пароль" required />
        {/* Comfirm Password */}
        <PasswordInputField meta={confirmPassword} placeholder="Подтвердите пароль" required />
        {/* Own Small Card
        <CheckboxInputField
          value={ownSmallCard}
          id="own-small-card"
          groupClasses="auth-register-form__checkbox"
          onChange={toggleOwnSmallCard}
        >
          <span>У меня есть карта Small</span>
        </CheckboxInputField>
        {ownSmallCard && (
          <TextInputField meta={cardNumber} type="number" placeholder="Введите номер карты" />
        )} */}
        {/* Own Small Card */}
        <CheckboxInputField
          value={confirmAgreement}
          id="confirm-agreement"
          groupClasses="auth-register-form__checkbox"
          onChange={toggleConfirmAgreement}
        >
          <span className="auth-register-form__agreement-label">
            Я подтверждаю, что ознакомлен и согласен с условиями{' '}
            <a href="https://www.google.com/" target={'_blank'}>
              Полити конфиденциальности
            </a>
          </span>
        </CheckboxInputField>
      </div>

      {registerError && <span className="form__auth-error">{registerError}</span>}

      <div className={'auth-form__footer'}>
        <AuthActions
          changeButtonLabel="Авторизация"
          changeFormType={() => changeFormType('login')}
          actionButtonLabel="Зарегистрироваться"
          actionButtonDisabled={!confirmAgreement || isSubmitting || loading}
          onAction={handleSubmitForm}
        />
      </div>
    </form>
  )
}

export default RegisterForm
