// hooks
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'

//components
import { NavLink, Outlet } from 'react-router-dom'
import RangeDatepicker from '@components/cabinet/my-purchases/range-datepicker/RangeDatepicker'
import SmallCardInactive from '@components/cabinet/small-card/SmallCardInactive'
import PromoSlider from '@components/cabinet/sliders/PromoSlider'

const MyPurCharesPage = () => {
  const { hasCard, loading } = useSelector((state) => state.profileSlice)
  const { pathname } = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    if (pathname === '/cabinet/my-purchases') {
      navigate('/cabinet/my-purchases/analysis')
    }
  }, [pathname])

  //   const loader = loading && 'Загрузка...'

  const withoutCardView =
    !loading && !hasCard ? (
      <div className="my-purchases-page my-purchases-page_without-card">
        <PromoSlider />
        <SmallCardInactive />
      </div>
    ) : null

  const withCardView =
    !loading && hasCard ? (
      <div className="my-purchases-page flex">
        <div className="my-purchases-page__header flex">
          <div className="my-purchases-page__nav-wrapper">
            <nav className="my-purchases-page__nav">
              <NavLink className="my-purchases-page__nav-item" to="/cabinet/my-purchases/analysis">
                Анализ покупок
              </NavLink>
              <NavLink
                className="my-purchases-page__nav-item"
                to="/cabinet/my-purchases/transaction"
              >
                Транзакции
              </NavLink>
            </nav>
          </div>
          <div className="my-purchases-pagee__datepicker-wrapper">
            <RangeDatepicker />
          </div>
        </div>
        <Outlet />
      </div>
    ) : null

  return (
    <>
      {/* {loader} */}
      {withoutCardView}
      {withCardView}
    </>
    // <>
    //   {hasCard ? (
    //     <div className="my-purchases-page flex">
    //       <div className="my-purchases-page__header flex">
    //         <div className="my-purchases-page__nav-wrapper">
    //           <nav className="my-purchases-page__nav">
    //             <NavLink
    //               className="my-purchases-page__nav-item"
    //               to="/cabinet/my-purchases/analysis"
    //             >
    //               Анализ покупок
    //             </NavLink>
    //             <NavLink
    //               className="my-purchases-page__nav-item"
    //               to="/cabinet/my-purchases/transaction"
    //             >
    //               Транзакции
    //             </NavLink>
    //           </nav>
    //         </div>
    //         <div className="my-purchases-pagee__datepicker-wrapper">
    //           <RangeDatepicker />
    //         </div>
    //       </div>
    //       <Outlet />
    //     </div>
    //   ) : (
    //     <div className="my-purchases-page my-purchases-page_without-card">
    //       <PromoSlider />
    //       <SmallCardInactive />
    //     </div>
    //   )}
    // </>
  )
}

export default MyPurCharesPage
