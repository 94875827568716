// hooks
import { useSelector } from 'react-redux'

// components
import { Link } from 'react-router-dom'
import DonatDiagramSkeleton from '@components/skeletons/diagrams/DonatDiagramSkeleton'

// assets
import '@assets/scss/components/cabinet/diagrams/economyDonatDiagram.scss'

const EconomyDonatDiagram = ({ total, saved, link, colors = true }) => {
  const { loading } = useSelector((state) => state.economySlice)
  let percentOfEconomy = Math.ceil((saved / total) * 100)
  const loader = loading ? <DonatDiagramSkeleton /> : null
  return (
    <>
      {loader}

      {!loading && (
        <>
          <div
            className="empty-circle-chart animate"
            style={{
              '--p': percentOfEconomy,
              '--b': '12px',
              '--c': percentOfEconomy ? '#58CA6A' : '#F3F3F4',
              '--bg': percentOfEconomy ? '#cbeece' : '#F3F3F4',
            }}
          >
            <p className="empty-circle-chart__text">{percentOfEconomy ? percentOfEconomy : 0}%</p>
          </div>
          <div className="empty-circle-chart__lables">
            <p className="empty-circle-chart__summary">
              {colors ? <span className="circle"></span> : null}
              Сумма покупок <span className="value"> {total ? total : 0}</span>
            </p>
            <p className="empty-circle-chart__econom">
              {colors ? <span className="circle"></span> : null}
              Экономия <span className="value"> {saved ? saved : 0}</span>
            </p>
            {link ? (
              <Link
                to="/cabinet/my-purchases/analysis"
                className="purchase-analysis__readmore button-readmore"
              >
                Узнать подробнее
              </Link>
            ) : null}
          </div>
        </>
      )}
    </>
  )
}

export default EconomyDonatDiagram
