import React from 'react'
import { Link } from 'react-router-dom'

const ClubCardSmall = ({ backgroundColor, className, title, img }) => {
  const style = {
    backgroundColor,
  }
  return (
    <Link to="/cabinet/clubs/1" className={`club-card-small-wrapper ${className}`}>
      <div className="club-card-small flex" style={style}>
        <img className="club-card-small__image" src={img} alt="club image" />
        <button className="club-card-small__button button ">"{`${title}`}"</button>
      </div>
    </Link>
  )
}

export default ClubCardSmall
