import useHttp from '@api'

const enpoint = '/cabinet/purchases'

const purchasesService = {
  async getAllPurchase(from, to) {
    try {
      const response = await useHttp().get(`${enpoint}?date_from=${from}&date_to=${to}`)
      return _PurchasesTransformer(response.data)
    } catch (e) {
      return e
    }
  },
}

export const _PurchasesTransformer = ({ categories, economy, transactions }) => {
  if (!categories.length || !transactions.length) return 'Нет данных'
  const sortedArr = categories.sort((a, b) => b.sum - a.sum)
  const summary = categories.reduce((current, { sum }) => sum + current, 0)

  const top4Category = sortedArr.slice(0, 4)
  const otherCategory = sortedArr.slice(4, sortedArr.length)
  const colorsArr = ['#2B96F0', '#51CD66', '#FD9128', '#FD5252', '#9B86EF']

  const newArr = [
    ...top4Category,
    {
      category_name: 'Другие товары',
      sum: otherCategory.reduce((current, { sum }) => sum + current, 0),
      category_list: otherCategory,
    },
  ].map((category, i) => {
    return {
      ...category,
      precent: Math.ceil((category.sum / summary) * 100),
      color: colorsArr[i],
    }
  })

  return { categories: newArr, economy, transactions }
}

export default purchasesService
