import React, { useRef } from 'react'
import { createPortal } from 'react-dom'

import CloseSvg from '@assets/icons/close.svg'
import styles from './PopUp.module.scss'

const PopUp = ({ children, onClose }) => {
  const overlayRef = useRef()
  const closeHandler = () => {
    onClose()
  }

  return createPortal(
    <div
      className={styles.overlay}
      ref={overlayRef}
      onClick={(e) => e.target == overlayRef.current && closeHandler()}
    >
      <div className={styles.popup}>
        <div className={`${styles.header} flex`}>
          <button
            className={`${styles['button-close']} flex`}
            onClick={() => closeHandler()} /// TODO сделать закрытие универсальным, сейчас зависет от карадПопапа
          >
            <img src={CloseSvg} alt="" />
          </button>
        </div>
        <div className={`${styles.body} flex`}>{children}</div>
      </div>
    </div>,
    document.body
  )
}

export default PopUp
