import React, { useState } from 'react'

//components
import PopUp from '@components/shared/popup/PopUp'
import OpenNewSmallCard from '@components/cabinet/popup-content/OpenNewSmallCard'
import BindPlasticCard from '@components/cabinet/small-card/BindPlasticCard'
import BonusCard from '@components/cabinet/small-card/BonusCard'
import Marketplaces from './Marketplaces'

const SmallCardInactive = () => {
  const [isTypeBindCard, setIsTypeBindCard] = useState(false)
  const [isOpenCardPopUpShown, setOpenCardPopUpState] = useState(false)

  const OpenNewCardPopUp = () => {
    return (
      <PopUp onClose={() => setOpenCardPopUpState(false)}>
        <OpenNewSmallCard onClose={() => setOpenCardPopUpState(false)} />
      </PopUp>
    )
  }

  return (
    <>
      <div className="small-card-inactive flex">
        <BonusCard isActive={false} />

        {!isTypeBindCard ? (
          <div className="small-card-inactive__action">
            <p className="small-card-inactive__text-about-app">
              Откройте карту SMALL и
              <a
                className="link--red"
                href="http://google.ru"
                target="_blank"
                rel="noopener noreferrer"
              >
                {' скачайте '}
              </a>
              наше приложение, чтобы копить бонусы и пользоваться всеми привилегиями!
            </p>
            <div className="small-card-inactive__action-buttons flex">
              <button
                type="button"
                className="button small-card-inactive__button-open button--red"
                onClick={() => setOpenCardPopUpState(true)}
              >
                Открыть электронную карту
              </button>
              <button
                type="button"
                className="button small-card-inactive__button-bind button--outlined"
                onClick={() => setIsTypeBindCard(true)}
              >
                Привязать пластиковую карту
              </button>
            </div>
          </div>
        ) : (
          <BindPlasticCard />
        )}
      </div>

      {isOpenCardPopUpShown && <OpenNewCardPopUp />}

      <Marketplaces />
    </>
  )
}

export default SmallCardInactive
