// hooks
import React from 'react'
import { useSelector } from 'react-redux'

//components
import ClubCardLarge from '@components/cabinet/club/ClubCardLarge'

const ClubItemsList = () => {
  const { clubs, loading, usersClubs } = useSelector((state) => state.clubsSlice)

  const clubItems = clubs
    ? clubs.map((item) => {
        return (
          <ClubCardLarge
            key={item.id}
            id={item.id}
            setId={item.set_id}
            image={item.img}
            title={item.title_ru}
            descr={item.description_ru}
            isJoined={item.in_segment}
            loading={loading}
          />
        )
      })
    : []

  return (
    <div>
      <div className="club-items-list__message flex">
        <h2 className="club-items-list__title">
          Количество клубов {usersClubs && usersClubs.length}/3
        </h2>
        <p className="club-items-list__message-text">
          Клуб можно менять не чаще 1 раза в месяц. Список SKU обновляется 1 раз в 1 месяц.
          Единовременно можно состоять максимум в трех клубах
        </p>
      </div>

      <div className="club-items-list__list flex">{clubItems}</div>
    </div>
  )
}

export default ClubItemsList
