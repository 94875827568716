// hooks
import React from 'react'
import { useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'

// actions
import { setCurrentMonth } from '@/store/slices/economySlice'

// components
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  CategoryScale,
  LinearScale,
  BarElement,
} from 'chart.js'
import { Bar, getElementAtEvent } from 'react-chartjs-2'

// assets
import '@assets/scss/components/cabinet/diagrams/economyBarDiagram.scss'

ChartJS.register(ArcElement, Tooltip, CategoryScale, LinearScale, BarElement)

const EconomyBarDiagram = ({ economy }) => {
  //   console.log(economy)
  const dispatch = useDispatch()
  const diagramRef = useRef()

  const diagramData = {
    labels: typeof economy === 'object' ? economy.map(({ month }) => month) : [``, ``, ``],
    datasets: [
      {
        data: typeof economy === 'object' ? economy.map(({ total }) => total) : [1, 1, 1],
        backgroundColor:
          typeof economy === 'object'
            ? ({ chart }) => {
                if (!chart.chartArea) return null
                return getGradient(chart, economy)
              }
            : ['#C1C1C1', '#C1C1C1', '#C1C1C1'],
        borderWidth: 0,
      },
    ],
    scale: {},
  }

  const diagramOpt = {
    // events: ['click'],
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      tooltip: {
        enabled: false,
      },
    },
  }

  function getGradient(chart, economy) {
    const {
      ctx,
      chartArea: { top, bottom },
      scales: { y },
    } = chart

    const arr = economy.map(({ saved }) => {
      const gradientSegment = ctx.createLinearGradient(0, bottom, 0, top)
      const border = (bottom - y.getPixelForValue(saved)) / (bottom - top)

      gradientSegment.addColorStop(0, '#51CD63')
      gradientSegment.addColorStop(border, '#51CD63')
      gradientSegment.addColorStop(border, '#CAEFD0')
      gradientSegment.addColorStop(1, '#CAEFD0')
      //   #F2F2F2 secondary bg
      //   #C1C1C1 secondary main
      //   gradientSegment.addColorStop(0, '#C1C1C1')
      //   gradientSegment.addColorStop(border, '#C1C1C1')
      //   gradientSegment.addColorStop(border, '#F2F2F2')
      //   gradientSegment.addColorStop(1, '#F2F2F2')

      return gradientSegment
    })
    return arr
  }

  const onClickHandler = (e, economy) => {
    if (getElementAtEvent(diagramRef.current, e).length > 0) {
      const datasetIndex = getElementAtEvent(diagramRef.current, e)[0].datasetIndex
      const dataPoint = getElementAtEvent(diagramRef.current, e)[0].index
      dispatch(setCurrentMonth(economy[dataPoint]))
    }
  }

  return (
    <Bar
      data={diagramData}
      options={diagramOpt}
      ref={diagramRef}
      onClick={(e) => onClickHandler(e, economy)}
      className="bar-diagram"
    />
  )
}

export default EconomyBarDiagram
