const fieldValidator = (value, role, required = true, minLen = 1, maxLen = 31) => {
  const EMAIL_REGEXP =
    /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/iu
  const NAME_REGEXP = /^[а-яё]+$/i

  if (value === '' && required) return 'Обязательное поле'

  if (role === 'name' && !NAME_REGEXP.test(value)) return `Только буквы кириллицы`

  if (role === 'surname' && !NAME_REGEXP.test(value) && value.length > 0) {
    return `Только буквы кириллицы`
  }

  if (role === 'email' && !EMAIL_REGEXP.test(value)) return `Неккоректный email`

  if (value.length <= minLen && value.length > 0) return `Минимум ${minLen + 1} символов`

  if (value.length >= maxLen && value.length > 0) return `Максимум ${maxLen - 1} символов`

  return false
}

export { fieldValidator }
