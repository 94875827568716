// hooks
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'

//components
import { Outlet } from 'react-router-dom'
import BirthdayBanner from '@components/cabinet/bonus-coupon/birthday-banner/BirthdayBanner'
import DefaultBanner from '@components/cabinet/bonus-coupon/default-banner/DefaultBanner'
import SmallCardInactive from '@components/cabinet/small-card/SmallCardInactive'
import PromoSlider from '@components/cabinet/sliders/PromoSlider'

const ClubsPage = () => {
  const { hasCard, isBirth, loading } = useSelector((state) => state.profileSlice)

  const loader = loading && 'Загрузка...'
  const birthBanner = !loading && isBirth ? <BirthdayBanner /> : null
  const defaultBanner = !loading && !isBirth ? <DefaultBanner /> : null

  const withoutCardView =
    !loading && !hasCard ? (
      <>
        <PromoSlider />
        <SmallCardInactive />
      </>
    ) : null

  const withCardView =
    !loading && hasCard ? (
      <>
        {birthBanner}
        {defaultBanner}
        <Outlet />
      </>
    ) : null

  return (
    <div className="clubs-page">
      {loader}
      {withoutCardView}
      {withCardView}
    </div>
  )
}

export default ClubsPage
