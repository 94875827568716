import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import clubsAPI from '@/services/clubsService'

//init
const name = 'clubsSlice'
const initialState = {
  clubs: null,
  usersClubs: null,
  loading: null,
  error: null,
  //   status: null,
}

//slice
export const clubsSlice = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // getAllClubs
    builder.addCase(loadClubs.pending, (state) => {
      state.loading = true
      state.error = null
    })
    builder.addCase(loadClubs.fulfilled, (state, action) => {
      state.loading = false
      state.clubs = action.payload
    })
    builder.addCase(loadClubs.rejected, (state, action) => {
      state.error = action.payload
    })

    // getAUsersClubs
    builder.addCase(getUserClubs.pending, (state) => {
      state.loading = true
      state.error = null
    })
    builder.addCase(getUserClubs.fulfilled, (state, action) => {
      state.loading = false
      state.usersClubs = action.payload
    })
    builder.addCase(getUserClubs.rejected, (state, action) => {
      state.error = action.payload
    })

    //joinClub
    builder.addCase(joinClub.pending, (state) => {
      state.loading = true
      state.error = null
    })
    builder.addCase(joinClub.fulfilled, (state, action) => {
      state.loading = false
      //   state.status = action.payload
      state.usersClubs = action.payload
    })
    builder.addCase(joinClub.rejected, (state, action) => {
      state.error = action.payload
    })

    //leaveClub
    builder.addCase(leaveClub.pending, (state) => {
      state.loading = true
      state.error = null
    })
    builder.addCase(leaveClub.fulfilled, (state, action) => {
      state.loading = false
      //   state.status = action.payload
      state.usersClubs = action.payload
    })
    builder.addCase(leaveClub.rejected, (state, action) => {
      state.error = action.payload
    })
  },
})

//thunks
export const loadClubs = createAsyncThunk(`${name}/loadClubs`, async (_, { rejectWithValue }) => {
  try {
    const response = await clubsAPI.getAllClubs()
    return response
  } catch (error) {
    console.error(error)
    return rejectWithValue(
      'При загрузке данных произошла ошибка. Пожалуйста, попробуйте повторить позже.'
    )
  }
})

export const getUserClubs = createAsyncThunk(
  `${name}/getUserClubs`,
  async (_, { rejectWithValue }) => {
    try {
      const response = await clubsAPI.getUsersClub()
      return response.clubs
    } catch (e) {
      console.error(e)
      rejectWithValue(
        'При загрузке данных произошла ошибка. Пожалуйста, попробуйте повторить позже.'
      )
    }
  }
)

export const joinClub = createAsyncThunk(
  `${name}/joinClub`,
  async (clubId, { dispatch, rejectWithValue }) => {
    try {
      const response = await clubsAPI.handleJoinClub(clubId)
      dispatch(loadClubs())
      return response.clubs
    } catch (error) {
      console.error(error)
      return rejectWithValue(
        'При загрузке данных произошла ошибка. Пожалуйста, попробуйте повторить позже.'
      )
    }
  }
)

export const leaveClub = createAsyncThunk(
  `${name}/leaveClub`,
  async (clubId, { dispatch, rejectWithValue }) => {
    try {
      const response = await clubsAPI.handleLeaveClub(clubId)
      dispatch(loadClubs())
      return response.clubs
    } catch (error) {
      console.error(error)
      return rejectWithValue(
        'При загрузке данных произошла ошибка. Пожалуйста, попробуйте повторить позже.'
      )
    }
  }
)

//actions
// export const { setClubs, setIsSubmitting } = clubsSlice.actions

export default clubsSlice.reducer
