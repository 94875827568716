import React, { useEffect } from 'react'
import { NavLink, Outlet, useLocation, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

// components
import BirthdayBanner from '@components/cabinet/bonus-coupon/birthday-banner/BirthdayBanner'
import DefaultBanner from '@components/cabinet/bonus-coupon/default-banner/DefaultBanner'
import SmallCardInactive from '@components/cabinet/small-card/SmallCardInactive'
import PromoSlider from '@components/cabinet/sliders/PromoSlider'

const BonusCoupon = () => {
  const { isBirth, loading, hasCard } = useSelector((state) => state.profileSlice)
  const { pathname } = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    if (pathname === '/cabinet/bonuses-coupons') {
      navigate('/cabinet/bonuses-coupons/all')
    }
  }, [pathname])

  const loader = loading && <span>Загрузка...</span>
  const birthBanner = !loading && isBirth ? <BirthdayBanner /> : null
  const defaultBanner = !loading && !isBirth ? <DefaultBanner /> : null
  const withoutCardView =
    !loading && !hasCard ? (
      <>
        <PromoSlider />
        <SmallCardInactive />
      </>
    ) : null

  const withCardView =
    !loading && hasCard ? (
      <>
        {birthBanner}
        {defaultBanner}
        <nav className="bonus-coupon-page__nav">
          <NavLink className="bonus-coupon-page__nav-item" to="/cabinet/bonuses-coupons/all">
            Все купоны
          </NavLink>
          <NavLink className="bonus-coupon-page__nav-item" to="/cabinet/bonuses-coupons/my">
            Мои купоны
          </NavLink>
          <NavLink className="bonus-coupon-page__nav-item" to="/cabinet/bonuses-coupons/archive">
            Архив
          </NavLink>
        </nav>
        <Outlet />
      </>
    ) : null

  return (
    <div className="bonus-coupon-page">
      {loader}
      {withCardView}
      {withoutCardView}
      {/* {hasCard ? (
        <>
          {birthBanner}
          {defaultBanner}
          <nav className="bonus-coupon-page__nav">
            <NavLink className="bonus-coupon-page__nav-item" to="/cabinet/bonuses-coupons/all">
              Все купоны
            </NavLink>
            <NavLink className="bonus-coupon-page__nav-item" to="/cabinet/bonuses-coupons/my">
              Мои купоны
            </NavLink>
            <NavLink className="bonus-coupon-page__nav-item" to="/cabinet/bonuses-coupons/archive">
              Архив
            </NavLink>
          </nav>
          <Outlet />
        </>
      ) : (
        <>
          <PromoSlider />
          <SmallCardInactive />
        </>
      )} */}
    </div>
  )
}

export default BonusCoupon
