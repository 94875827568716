import React, { useState } from "react"

const ChangePassword = () => {
  const [oldPassword, setOldPassword] = useState("")
  const [newPassword, setNewPassword] = useState("")
  const [confirmNewPassword, setConfirmNewPassword] = useState("")

  const handleSubmit = (e) => {
    e.preventDefault()
    console.log({
      oldPassword,
      newPassword,
      confirmNewPassword,
    })
  }

  return (
    <div className="change-password">
      <form className="change-password__form" onSubmit={handleSubmit}>
        <div className={"change-password__form-group form__group"}>
          <input
            value={oldPassword}
            className={"form__input"}
            type="text"
            placeholder="Старый пароль"
            onChange={(e) => setOldPassword(e.target.value)}
          />
        </div>
        <div className={"change-password__form-group form__group"}>
          <input
            value={newPassword}
            className={"form__input"}
            type="text"
            placeholder="Новый пароль"
            onChange={(e) => setNewPassword(e.target.value)}
          />
        </div>
        <div className={"change-password__form-group form__group"}>
          <input
            value={confirmNewPassword}
            className={"form__input"}
            type="text"
            placeholder="Подтвердите новый пароль"
            onChange={(e) => setConfirmNewPassword(e.target.value)}
          />
        </div>
        <div className="change-password__actions pop-up__actions">
          <button className="change-password__button button button--red" onSubmit={handleSubmit}>
            Сохранить
          </button>
        </div>
      </form>
    </div>
  )
}

export default ChangePassword
