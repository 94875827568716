import ContentLoader from 'react-content-loader'

const PieCategoriesSkeleton = (props) => (
  <ContentLoader viewBox="0 0 400 150" style={{ width: '100%', height: 'auto' }} {...props}>
    <circle cx="10" cy="20" r="8" />
    <rect x="25" y="15" rx="5" ry="5" style={{ width: '100%', height: '10px' }} />
    <circle cx="10" cy="50" r="8" />
    <rect x="25" y="45" rx="5" ry="5" style={{ width: '100%', height: '10px' }} />
    <circle cx="10" cy="80" r="8" />
    <rect x="25" y="75" rx="5" ry="5" style={{ width: '100%', height: '10px' }} />
    <circle cx="10" cy="110" r="8" />
    <rect x="25" y="105" rx="5" ry="5" style={{ width: '100%', height: '10px' }} />
  </ContentLoader>
)

export default PieCategoriesSkeleton
