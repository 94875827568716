import useHttp from '@api'

const enpoints = {
  create: '/cabinet/card/create',
  reissue: '/cabinet/card/reissue',
  get: '/cabinet/card/get-user-card',
}

const smallCardService = {
  createSmallCard: async () => {
    try {
      const response = await useHttp().post(enpoints.create)

      //   if (response?.data?.token) {
      //     const token = response.data
      //   }

      return response
    } catch (e) {
      return e
    }
  },

  reissueSmallCard: async (data) => {
    try {
      const response = await useHttp().post(enpoints.login, data)

      if (response.data) {
        const token = response.data.token
      }

      return response.data
    } catch (e) {
      return e
    }
  },

  deleteSmallCard: async (cardNumber) => {
    try {
      const response = await useHttp().post(`/cabinet/card/delete?number=2480100000213`)

      //   if (response.data) {
      //     const token = response.data.token
      //   }

      return response
    } catch (e) {
      return e
    }
  },

  getSmallCard: async () => {
    try {
      const response = await useHttp().get(enpoints.get)

      //   if (response.data) {
      //     const token = response.data.token
      //   }

      return response
    } catch (e) {
      return e
    }
  },
}

export default smallCardService
