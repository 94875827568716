// hooks
import { useSelector } from 'react-redux'

// components
import EconomyBarDiagram from '@components/cabinet/my-purchases/diagrams/EconomyBarDiagram'
import EconomyDonatDiagram from '@components/cabinet/my-purchases/diagrams/EconomyDonatDiagram'
import BarDiagramSkeleton from '@components/skeletons/diagrams/BarDiagramSkeleton'

const BarDiagramLayout = () => {
  const { economyForThreeMonth, loading, error } = useSelector((state) => state.economySlice)
  const { total, saved } = useSelector((state) => state.economySlice.currentMonth)

  const barLoader = loading && <BarDiagramSkeleton />
  const barContent =
    !error && !loading && economyForThreeMonth ? (
      <EconomyBarDiagram
        economy={economyForThreeMonth}
        className="my-purchases-analysis-page__bar-diagram"
      />
    ) : null

  const errorMessage = error && error

  return (
    <>
      <div className="my-purchases-analysis-page__empty-bottom flex">
        <div className="my-purchases-analysis-page__empty-bottom-left">
          {errorMessage}
          {barLoader}
          {barContent}
        </div>
        <div className="my-purchases-analysis-page__empty-bottom-right">
          <div className="my-purchases-analysis-page__donat-diagram">
            <EconomyDonatDiagram total={total} saved={saved} />
          </div>
        </div>
      </div>
    </>
  )
}

export default BarDiagramLayout
