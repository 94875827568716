import React from 'react-dom'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import ReactECharts from 'echarts-for-react'
import EconomyDonatDiagram from '../my-purchases/diagrams/EconomyDonatDiagram'

const PurchaseAnalysisCard = () => {
  const { economyForThreeMonth, error, loading } = useSelector((state) => state.economySlice)

  const totalEconomy = economyForThreeMonth?.reduce((acc, { total }) => acc + total, 0)
  const savedEconomy = economyForThreeMonth?.reduce((acc, { saved }) => acc + saved, 0)

  const loader = loading && <span>Загрузка...</span>
  const errorMessage = error && error
  const incomingContent =
    !loading && !error && economyForThreeMonth ? (
      <EconomyDonatDiagram total={totalEconomy} saved={savedEconomy} link={true} colors={false} />
    ) : null

  return (
    <>
      <div className="purchase-analysis">
        <h4 className="purchase-analysis__title">Анализ покупок</h4>
        <div className="purchase-analysis__diagram">
          {loader}
          {errorMessage}
          {incomingContent}
        </div>
      </div>
    </>
  )
}

export default PurchaseAnalysisCard
